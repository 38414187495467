<template>
    <div >
        <!-- class="fpmenutabs" type="card" -->
        <a-tabs v-if="istab" v-has="'未分配'" v-model:activeKey="fpactiveKey" class="fpmenutabs2" @change="changtab">
            <a-tab-pane key="11" tab="未分配"></a-tab-pane>
            <a-tab-pane key="12" tab="已分配"></a-tab-pane>
        </a-tabs>
        <!-- {{limits}} -->
        <div class="flexwrap">
            <div class="btn-list">
                <a-button @click="toAdd" v-has="'添加'"  type="primary">新增客户</a-button>
                <a-button @click="toAllocation" v-has="'分配线索'" type="primary">分配线索</a-button>
                <a-button @click="toPush" v-has="'推送线索'" type="primary">推送线索</a-button>
                <a-button @click="delMore" v-has="'删除'" type="primary" danger>删除</a-button>
                <a-button @click="giveupMore" v-has="'放弃'" type="primary" danger>放弃线索</a-button>
                <a-button v-has="'导出'"  @click="openExoprt">导出</a-button>
            </div>
            <a-button class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
        </div>
        <a-divider style="margin:16px 0" />
        <div class="search">
            <div class="left-option">
                <!-- <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist.date" />

                <a-select
                    ref="select" v-model:value="searchLlist.seat" style="width: 120px;margin-left:14px"
                    placeholder="所属人"
                    allowClear
                >
                    <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                </a-select>

                <a-select ref="select" v-model:value="searchLlist.jg" style="width: 120px;margin-left:14px"
                    placeholder="机构"
                    allowClear
                >
                    <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="searchLlist.verify_state" style="width: 120px;margin-left:14px"
                    placeholder="核实状态"
                    allowClear
                >
                    <a-select-option value="0">待核实</a-select-option>
                    <a-select-option value="1">有效</a-select-option>
                    <a-select-option value="2">无效</a-select-option>
                </a-select>

                <a-input style="width:90px;margin-left:14px" placeholder="客户姓名" v-model:value="searchLlist.username" /> -->
                <a-input style="width:120px;margin-left:14px" placeholder="线索号码" v-model:value="searchLlist.phone" />
                <a-select v-if="istab" ref="select" placeholder="推送状态" style="width: 120px;margin-left:14px"  v-model:value="searchLlist.is_push"   allowClear>
                    <a-select-option value="0">未推送</a-select-option>
                    <a-select-option value="1">已推送</a-select-option>
                </a-select>
                 <a-select ref="select" v-model:value="searchLlist.clue_project_id" style="width: 120px;margin-left:14px"
                    placeholder="项目"
                    allowClear
                >
                    <a-select-option v-for="item in projectList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                </a-select>
                <a-button @click="toSearch" type="primary">查询</a-button>
            </div>
            <div class="table-wrap">
                <!-- {{}} -->
                <a-table  :customRow="doubleClick" row-key="id" :scroll="{ x: 2340 }" :pagination="false" :columns="columns" :data-source="dataList.arr" size="small"  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                    <!-- <template #headerCell="{ column }">
                        <template v-if="column.key === 'state'" >
                            <a-tooltip class="table-wrap-toolstip" placement="top" color="#ffffff">
                                <template #title>
                                    <p>机构人员在备注里，标记状态只有四种情况，备注规则及解释如下：</p>
                                    <p>【有效】——即本地顾客并有在了解种植牙的价格；例：【有效】（后面无需再加任何备注）</p>
                                    <p>【无效】——异地需求者（临时在异地不算），或者根本无需求；>例：【无效】非本地患者，价格合适也不会来本地就诊，疑惑无需求的用户。</p>
                                    <p>【未接通】——48小时内分不同时段呼叫3次及以上无人接听（可用手机呼叫）；包括停机、空号；例：【未接通】第一次未接通</p>
                                    <p>【重复】——提供重复凭证，并同时提供回访记录，2个月以内要有回访记录，2个月以内无回访记录，则算作有效；例：【重复】（同时上传截图凭证即可，无需在后面再备注）</p>
                                    <p>由于精准派单都具有时效性，所以请在派送之后及时按备注提示进行跟进，超过24小时不进行状态选择，将系统将默认为有效并无法更改状态。</p>
                                </template>
                                线索状态 <QuestionCircleOutlined />
                            </a-tooltip>
                        </template>
                    </template> -->
                    
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'sex'">
                            <span v-if="record.sex==1">男</span>
                            <span v-if="record.sex==2">女</span>

                            <a-popover title="性别" trigger="click">
                                <template #content>
                                    <a-radio-group v-model:value="record.sex" name="radioGroup">
                                        <a-radio :value="1">男</a-radio>
                                        <a-radio :value="2">女</a-radio>
                                    </a-radio-group>
                                    <a @click="editSex(record.id,record.sex)">确定</a>
                                </template>
                                <edit-outlined style="margin:3px 0 0 6px"  v-has="'编辑'"/>
                            </a-popover>
                        </template>

                        <template v-if="column.dataIndex === 'phone'">
                            <span v-has="'呼叫'" @click.stop="toTel(record.id,record.phone)"><phone-filled :rotate="90" :style="{ color: '#409eff' ,marginRight:'6px',cursor:'pointer'}" /></span>
                            <span>{{record.phone}}</span>
                        </template>
                        <template v-if="column.dataIndex === 'push_time'">
                            <span v-if="record.push_time!=0">{{ changeFormdate(record.push_time) }}</span>
                            <span v-else></span>
                        </template>

                         <template v-if="column.dataIndex === 'is_push'">
                            <a-tag v-if="record.is_push==1" color="green">已推送</a-tag>
                            <a-tag v-else >未推送</a-tag>
                        </template>

                        <template v-if="column.dataIndex === 'clue_state'">
                            <a-tag v-if="record.clue_state==0" color="blue">待确认</a-tag>
                            <a-tag v-if="record.clue_state==1" color="green">客户</a-tag>
                            <a-tag v-if="record.clue_state==2" color="purple">待回访</a-tag>
                            <a-tag v-if="record.clue_state==3" >无效</a-tag>
                        </template>

                        <template v-if="column.dataIndex === 'update_time'">
                            <span v-if="record.update_time==0"></span>
                            <span v-else>{{record.update_time}}</span>
                        </template>
                        <template v-if="column.dataIndex === 'create_time'">
                            <span v-if="record.create_time==0"></span>
                            <span v-else>{{record.create_time}}</span>
                        </template>

                        <template v-if="column.dataIndex === 'clue_tags_arr'">
                            <a-tag v-for="item in record.clue_tags_arr" :key="item.id" color="blue">{{item.title}}</a-tag>
                        </template>


                        <template v-if="column.dataIndex === 'age'">
                            <span v-if="record.age==0" ></span>
                            <span v-else>{{record.age}}</span>
                            <a-popover title="年龄" trigger="click">
                                <template #content>
                                    <a-input v-model:value="record.age" placeholder="年龄" />
                                    <a  @click="editAge(record.id,record.age)" >确定</a>
                                </template>
                                <edit-outlined style="margin:3px 0 0 6px"  v-has="'编辑'"/>
                            </a-popover>
                        </template>
                        <template v-if="column.dataIndex === 'customer_details'">
                            <span>{{record.customer_details}}</span>
                            <a-popover title="顾客详情备注" trigger="click">
                                <template #content>
                                    <a-textarea v-model:value="record.customer_details" placeholder="顾客详情备注" :rows="4" />
                                    <a  @click="editCustomer(record.id,record.customer_details)" >确定</a>
                                </template>
                                <edit-outlined style="margin:3px 0 0 6px"  v-has="'编辑'"/>
                            </a-popover>
                        </template>
                         <template v-if="column.dataIndex === 'title_clue_project'">
                           {{record.title_clue_project}}
                           <!-- （{{record.clue_project_abbr}}） -->
                        </template>
                        <template v-if="column.dataIndex === 'action'">
                            
                            <!-- <a-button type="link" v-has="'编辑线索'" @click.stop="openEdit(record.id)">编辑</a-button> -->
                            <a-button type="link" @click.stop="openAll(record.id)">详情</a-button>
                            <a-button type="link" v-has="'分配线索'" @click.stop="toAllocationone(record.id)">分配</a-button>
                            <a-button type="link" v-has="'推送线索'" @click.stop="toPushone(record.id)">推送</a-button>
                            <a-button type="link" v-has="'放弃'" @click.stop="giveup(record.id)">放弃线索</a-button>
                        </template>
                    </template>
                </a-table>
                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>

        </div>

        <!-- 新增线索 -->
        <a-modal v-model:open="clueOpen" title="新增客户" @ok="handleOkAdd">
            <a-tabs v-model:activeKey="activeKey">
                <a-tab-pane key="1" tab="手动录入">
                    <div>
                        <a-form
                        style="height:460px;overflow-y:auto"
                            name="basic"
                            :label-col="{ span: 4 }"
                            :wrapper-col="{ span: 19 }"
                            autocomplete="off"
                        >
                            <a-form-item label="所属项目" name="username" >
                                <a-select ref="select" placeholder="所属项目" v-model:value="formData.clue_project_id"   >
                                    <a-select-option v-for="item in projectList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item label="姓名" name="username" >
                                <a-input placeholder="客户姓名" v-model:value="formData.username" />
                            </a-form-item>
                            <a-form-item label="电话" name="phone" >
                                <a-input placeholder="客户电话" v-model:value="formData.phone" />
                            </a-form-item>
                            <a-form-item label="性别" name="sex" >
                                <a-radio-group v-model:value="formData.sex" name="radioGroup">
                                    <a-radio value="1">男性</a-radio>
                                    <a-radio value="2">女性</a-radio>
                                </a-radio-group>
                            </a-form-item>
                            <a-form-item label="年龄" name="age" >
                                <a-input placeholder="年龄" v-model:value="formData.age" />
                            </a-form-item>
                            <a-form-item label="地址" name="address" >
                                <a-textarea v-model:value="formData.address" placeholder="地址" :rows="2" />
                            </a-form-item>
                            <!-- <a-form-item label="所属机构" name="organ_id" >
                                <a-select
                                    ref="select" v-model:value="formData.organ_id" 
                                    placeholder="所属机构"
                                    allowClear
                                >
                                    <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                                </a-select>
                            </a-form-item> -->
                            <a-form-item label="顾客详情" name="customer_details" >
                                <a-textarea v-model:value="formData.customer_details" placeholder="顾客详情" :rows="4" />
                            </a-form-item>
                            <a-form-item label="上传录音" >
                                <a-upload
                                    v-model:file-list="fileAudio"
                                    name="file"
                                    action="https://pdxt.13524.net/admin/addaudiofile"
                                    :headers="headers"
                                    @change="handleChangeAdd"
                                >
                                    <a-button size="small"><upload-outlined></upload-outlined>上传录音</a-button>
                                </a-upload>
                            </a-form-item>
                            <a-form-item label="广告名称" name="ad_project_name" >
                                <a-input placeholder="广告名称" v-model:value="formData.ad_project_name" />
                            </a-form-item>
                            <a-form-item label="广告计划" name="ad_plan_name" >
                                <a-input placeholder="广告计划" v-model:value="formData.ad_plan_name" />
                            </a-form-item>
                            <a-form-item label="广告来源" name="ad_source_name" >
                                <a-input placeholder="广告来源" v-model:value="formData.ad_source_name" />
                            </a-form-item>
                            
                        </a-form>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="通过Excel导入" >
                     <a-form
                        name="basic"
                        :label-col="{ span: 4 }"
                        :wrapper-col="{ span: 19 }"
                        autocomplete="off"
                    >
                        <a-form-item label="所属项目" >
                            <a-select
                                ref="select" v-model:value="uploadData.jq" 
                                placeholder="所属项目"
                                allowClear
                            >
                                <a-select-option v-for="item in projectList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                            </a-select>
                        </a-form-item>
                                  <!-- {{fileList2}} -->
                        <a-upload-dragger
                            v-model:fileList="fileList2"
                            name="file"
                            :headers="headers"
                            action="https://pdxt.13524.net/admin/addxlsfile"
                            @change="handleChangeExport"
                        >
                            <p class="ant-upload-drag-icon">
                            <inbox-outlined></inbox-outlined>
                            </p>
                            <p class="ant-upload-text">将文件拖拽到这来，或点击这里</p>
                        </a-upload-dragger>
                    </a-form>
                     <a-form-item label="模板下载" name="username" >
                       <a href="https://pdxt.13524.net/storage/clue/%E7%BA%BF%E7%B4%A2.xls">模板下载</a>
                    </a-form-item>
                </a-tab-pane>
            </a-tabs>
        </a-modal>


        <!-- 编辑 -->
          <a-modal v-model:open="editOpen" title="编辑线索" @ok="edithandleOk">

            <div style="height:460px;overflow-y:auto">
                <a-form
                    name="basic"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 18 }"
                    autocomplete="off"
                >
                    <block v-has="'编辑线索'">
                        <a-form-item label="姓名" name="username" >
                            <a-input placeholder="客户姓名" v-model:value="editData.data.username" />
                        </a-form-item>
                        <a-form-item label="电话" name="phone" >
                            <a-input placeholder="客户电话" v-model:value="editData.data.phone" />
                        </a-form-item>
                        <a-form-item label="性别" name="sex" >
                            <a-radio-group v-model:value="editData.data.sex" name="sex">
                                <a-radio :value="1">男性</a-radio>
                                <a-radio :value="2">女性</a-radio>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item label="年龄" name="age" >
                            <a-input placeholder="年龄" v-model:value="editData.data.age" />
                        </a-form-item>
                         <a-form-item label="地址" name="address" >
                            <a-textarea v-model:value="editData.data.address" placeholder="地址" :rows="1" />
                        </a-form-item>
                        <a-form-item label="顾客详情" name="customer_details" >
                            <a-textarea v-model:value="editData.data.customer_details" placeholder="顾客详情" :rows="4" />
                        </a-form-item>
                        <a-form-item label="录音" >
                            <audio  v-if="editData.data.file_url" :src="editData.data.file_url" controls></audio>
                            <a-upload
                                v-model:file-list="fileAudio"
                                name="file"
                                action="https://pdxt.13524.net/admin/addaudiofile"
                                :headers="headers"
                                @change="handleChangeEdit"
                            >
                                <a-button size="small"><upload-outlined></upload-outlined>上传录音</a-button>
                            </a-upload>
                        </a-form-item>
                    </block>
                   
                    <a-form-item label="所属机构" name="organ_id" v-has="'所属机构'">
                        <a-select
                            ref="select" v-model:value="formData.organ_id" 
                            placeholder="所属机构"
                            allowClear
                        >
                            <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="机构备注" name="organ_remarks" v-has="'机构备注'">
                        <a-textarea v-model:value="editData.data.organ_remarks" placeholder="机构备注" :rows="4" />
                    </a-form-item>
                    <a-form-item label="状态" name="state" v-has="'编辑状态'">
                        <a-radio-group v-model:value="editData.data.state" >
                            <a-radio :value="0" >待确认</a-radio>
                            <a-radio :value="1" >有效</a-radio>
                            <a-radio :value="2" >未接通</a-radio>
                            <a-radio :value="3" >重单</a-radio>
                            <a-radio :value="4" >无效</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="核实" name="verify_state" v-has="'线索验证'">
                        <a-radio-group v-model:value="editData.data.verify_state">
                            <a-radio :value="0">待核实</a-radio>
                            <a-radio :value="1">有效</a-radio>
                            <a-radio :value="2">无效</a-radio>
                        </a-radio-group>
                    </a-form-item>
                </a-form>
            </div>

        </a-modal>

         <a-modal v-model:open="editOpen2" title="线索状态" @ok="edithandleOk2" >
            <div >
                <a-form
                    name="basic"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 19 }"
                    autocomplete="off"
                >
                    <a-form-item label="线索状态" name="clue_state" >
                        <a-radio-group v-model:value="editData.data.clue_state" @change="getTags">
                            <a-radio :value="0" >待确认</a-radio>
                            <a-radio :value="1" >客户</a-radio>
                            <a-radio :value="2" >待回访</a-radio>
                            <a-radio :value="3" >无效</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item label="线索标签" name="clue_state" >
                        <a-checkbox-group v-model:value="editData.data.clue_tags">
                            <a-checkbox v-for="item in taglist.arr" :key="item.id" :value="item.id">{{item.title}}</a-checkbox>
                        </a-checkbox-group>
                    </a-form-item>
                </a-form>
            </div>
        </a-modal>

        <a-modal v-model:open="editOpen3" title="线索验证" @ok="edithandleOk" >
            <div v-has="'线索验证'">
                <a-form
                    name="basic"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 19 }"
                    autocomplete="off"
                >
                    <a-form-item label="核实" name="verify_state" >
                        <a-radio-group v-model:value="editData.data.verify_state">
                            <a-radio :value="0">待核实</a-radio>
                            <a-radio :value="1">有效</a-radio>
                            <a-radio :value="2">无效</a-radio>
                        </a-radio-group>
                    </a-form-item>
                </a-form>
            </div>

        </a-modal>

        <!-- 通话记录 -->
        <a-drawer  v-model:open="openRecord" title="通话记录" placement="right" size="large">
            <template #extra>
                <a-button style="border-radius:6px 0 0 6px;border-right:0" @click="switchPage(1)" ><LeftOutlined /></a-button>
                <a-button style="border-radius:0px 6px 6px 0px" @click="switchPage(2)" ><RightOutlined /></a-button>
        </template>
            <div class="drawer-wrap">
                <div class="drawer-wrap-top">
                    <a-descriptions :column="2">
                        <a-descriptions-item :span="2" label="手机号码">{{editData.data.phone}}  
                            <a-button v-has="'呼叫'" style="margin-left:14px" size="small" type="primary" @click="toTel(editData.data.id,editData.data.phone)">呼叫</a-button>
                            <a-button v-if="ishangup" style="margin-left:14px" size="small" type="primary" danger @click="toHangup">挂断</a-button>
                            <div class="timeContainer">{{ teltime }}</div>
                        </a-descriptions-item>
                        <a-descriptions-item label="手机号码归属地">{{editData.data.phone_belong_place}}</a-descriptions-item>
                        <a-descriptions-item label="自动定位城市">{{editData.data.address}}</a-descriptions-item>
                        <a-descriptions-item label="归属人">{{editData.data.belong_admin_user_username}}</a-descriptions-item>
                        <a-descriptions-item label="跟进状态"> 
                            <a-tag @click="openState" v-if="editData.data.clue_state==0" color="blue">待确认</a-tag>
                            <a-tag @click="openState" v-if="editData.data.clue_state==1" color="green">客户</a-tag>
                            <a-tag @click="openState" v-if="editData.data.clue_state==2" color="purple">待回访</a-tag>
                            <a-tag @click="openState" v-if="editData.data.clue_state==3" >无效</a-tag>
                             <a-tooltip class="table-wrap-toolstip" placement="top" color="#ffffff">
                                <template #title>
                                    <p>机构人员在备注里，标记状态只有四种情况，备注规则及解释如下：</p>
                                    <p>【有效】——即本地顾客并有在了解种植牙的价格；例：【有效】（后面无需再加任何备注）</p>
                                    <p>【无效】——异地需求者（临时在异地不算），或者根本无需求；>例：【无效】非本地患者，价格合适也不会来本地就诊，疑惑无需求的用户。</p>
                                    <p>【未接通】——48小时内分不同时段呼叫3次及以上无人接听（可用手机呼叫）；包括停机、空号；例：【未接通】第一次未接通</p>
                                    <p>【重复】——提供重复凭证，并同时提供回访记录，2个月以内要有回访记录，2个月以内无回访记录，则算作有效；例：【重复】（同时上传截图凭证即可，无需在后面再备注）</p>
                                    <p>由于精准派单都具有时效性，所以请在派送之后及时按备注提示进行跟进，超过24小时不进行状态选择，将系统将默认为有效并无法更改状态。</p>
                                </template>
                                 <QuestionCircleOutlined />
                            </a-tooltip>
                        </a-descriptions-item>
                      
                        <a-descriptions-item label="最后跟进">
                            <span v-if="editData.data.update_time==0"></span>
                            <span v-else>{{editData.data.update_time}}</span>
                        </a-descriptions-item>
                        <a-descriptions-item label="跟进标签" :span="2">
                            <a-tag v-for="item in editData.data.clue_tags_arr" :key="item.id" color="blue">{{item.title}}</a-tag>
                        </a-descriptions-item>
                    </a-descriptions>
                </div>
                <a-tabs >
                    <a-tab-pane key="3" tab="线索信息" class="reocrd-style">

                        <a-descriptions title="基本信息" :column="2">
                            <template #extra>
                                <a-button v-has="'编辑'" v-if="isEdit==false" @click="isDrawerEdit" type="primary">编辑</a-button>
                                <a-button v-if="isEdit==true" type="primary" @click="edithandleOk" style="margin-right:12px">确定</a-button>
                                <a-button v-if="isEdit==true" @click="quitDrawerEdit" >取消</a-button>
                            </template>
                            <a-descriptions-item label="姓名">
                                <p v-if="isEdit==false">{{editData.data.username}}</p>
                                <a-input v-else v-model:value="editData.data.username"></a-input>
                            </a-descriptions-item>
                            
                            <a-descriptions-item label="电话">
                                <p v-if="isEdit==false">{{editData.data.phone}}</p>
                                <a-input v-else v-model:value="editData.data.phone"></a-input>
                            
                            </a-descriptions-item>
                            <a-descriptions-item label="性别">
                                <p v-if="isEdit==false">
                                    <span v-if="editData.data.sex==1">男</span>
                                    <span v-if="editData.data.sex==2">女</span>
                                </p>
                                <a-radio-group v-else v-model:value="editData.data.sex" name="radioGroup">
                                    <a-radio :value="1">男</a-radio>
                                    <a-radio :value="2">女</a-radio>
                                </a-radio-group>
                            </a-descriptions-item>
                            <a-descriptions-item label="年龄">
                               <p v-if="isEdit==false&&editData.data.age!=0">{{editData.data.age}}</p>
                                <p v-if="isEdit==false&&editData.data.age==0"></p>
                                <a-input v-if="isEdit==true" v-model:value="editData.data.age"></a-input>
                            </a-descriptions-item>
                            <a-descriptions-item label="微信" >
                                <p v-if="isEdit==false">{{editData.data.wx_chat}}</p>
                                <a-input v-else v-model:value="editData.data.wx_chat"></a-input>
                            </a-descriptions-item>
                            <a-descriptions-item label="QQ或邮箱" >
                                <p v-if="isEdit==false">{{editData.data.QQ}}</p>
                                <a-input v-else v-model:value="editData.data.QQ"></a-input>
                            </a-descriptions-item>
                            <a-descriptions-item label="地址" :span="2">
                                <p v-if="isEdit==false">{{editData.data.address}}</p>
                                <a-textarea v-else v-model:value="editData.data.address" placeholder="地址" :rows="2" />
                            </a-descriptions-item>
                            <a-descriptions-item label="顾客详情" :span="2">
                                <p v-if="isEdit==false">{{editData.data.customer_details}}</p>
                                <a-textarea v-else v-model:value="editData.data.customer_details" placeholder="顾客详情" :rows="4" />
                            </a-descriptions-item>
                            

                            <a-descriptions-item label="线索状态" :span="2">
                                <p v-if="isEdit==false" >
                                    <a-tag v-if="editData.data.clue_state==0" color="blue">待确认</a-tag>
                                    <a-tag v-if="editData.data.clue_state==1" color="green">客户</a-tag>
                                    <a-tag v-if="editData.data.clue_state==2" color="purple">待回访</a-tag>
                                    <a-tag v-if="editData.data.clue_state==3" >无效</a-tag>
                                </p>
                                <a-radio-group @change="getTags"  v-else v-model:value="editData.data.clue_state" name="radioGroup">
                                    <!-- <a-radio :value="0" >待确认</a-radio> -->
                                    <a-radio :value="1" >客户</a-radio>
                                    <a-radio :value="2" >待回访</a-radio>
                                    <a-radio :value="3" >无效</a-radio>
                                </a-radio-group>
                                <a-tooltip class="table-wrap-toolstip" placement="top" color="#ffffff">
                                    <template #title>
                                        <p>机构人员在备注里，标记状态只有四种情况，备注规则及解释如下：</p>
                                        <p>【有效】——即本地顾客并有在了解种植牙的价格；例：【有效】（后面无需再加任何备注）</p>
                                        <p>【无效】——异地需求者（临时在异地不算），或者根本无需求；>例：【无效】非本地患者，价格合适也不会来本地就诊，疑惑无需求的用户。</p>
                                        <p>【未接通】——48小时内分不同时段呼叫3次及以上无人接听（可用手机呼叫）；包括停机、空号；例：【未接通】第一次未接通</p>
                                        <p>【重复】——提供重复凭证，并同时提供回访记录，2个月以内要有回访记录，2个月以内无回访记录，则算作有效；例：【重复】（同时上传截图凭证即可，无需在后面再备注）</p>
                                        <p>由于精准派单都具有时效性，所以请在派送之后及时按备注提示进行跟进，超过24小时不进行状态选择，将系统将默认为有效并无法更改状态。</p>
                                    </template>
                                    <QuestionCircleOutlined />
                                </a-tooltip>
                            </a-descriptions-item>

                            <a-descriptions-item label="线索标签" :span="2">
                                <p v-if="isEdit==false" >
                                    <a-tag v-for="item in editData.data.clue_tags_arr" :key="item.id" color="blue">{{item.title}}</a-tag>
                                </p>
                              
                                <a-checkbox-group v-else  v-model:value="editData.data.clue_tags">
                                    <a-checkbox v-for="item in taglist.arr" :key="item.id" :value="item.id">{{item.title}}</a-checkbox>
                                </a-checkbox-group>
                                  <!-- {{editData.data.clue_tags}} -->
                            </a-descriptions-item>

                            <a-descriptions-item label="录音" :span="2">
                                <audio  v-if="isEdit==false&&editData.data.file_url" :src="editData.data.file_url" controls></audio>
                                <a-upload
                                    v-else 
                                    v-model:file-list="fileAudio"
                                    name="file"
                                    action="https://pdxt.13524.net/admin/addaudiofile"
                                    :headers="headers"
                                    @change="handleChangeEdit"
                                >
                                    <a-button size="small"><upload-outlined></upload-outlined>上传录音</a-button>
                                </a-upload>
                                
                            </a-descriptions-item>
                        </a-descriptions>
                        <a-divider />
                         <!-- <a-descriptions title="线索状态" :column="1">
                            <template #extra>
                                <a-button v-has="'编辑'" v-if="isEdit2==false" @click="isDrawerEdit2" type="primary">编辑</a-button>
                                <a-button v-if="isEdit2==true" type="primary" @click="edithandleOk2" style="margin-right:12px">确定</a-button>
                                <a-button v-if="isEdit2==true" @click="quitDrawerEdit2" >取消</a-button>
                            </template>
                            
                         </a-descriptions> -->

                    </a-tab-pane>
                    <a-tab-pane  key="6" tab="操作记录" >
                        <div style="height:800px">
                            <a-timeline>
                                <template v-for="item in allList.arr" :key="item.id">
                                    <a-timeline-item v-if="item.type==1">
                                        <h2 class="drawer-wrap-bottom-title">{{item.title}} {{item.create_time}}</h2>
                                        <div class="drawer-wrap-bottom">
                                        <p>修改人：{{item.username_admin_user}}</p>  
                                        <p>修改内容：{{item.follow_up_remarks}}</p>
                                        </div>
                                    </a-timeline-item>
                                    <a-timeline-item v-if="item.type==2">
                                        <h2 class="drawer-wrap-bottom-title">通话记录 {{item.create_time}}</h2>
                                        <div class="drawer-wrap-bottom">
                                            <!-- <p>通话状态：未接通</p>  -->
                                            <p>被叫号码：{{item.called_number}}</p> 
                                            <p>通话时长：{{item.call_second}}</p> 
                                            <audio v-if="item.file_url" :src="item.file_url" controls></audio>
                                        </div>
                                    </a-timeline-item>
                                </template>
                            


                        </a-timeline>
                        </div>
                    
                    </a-tab-pane>

                    <a-tab-pane  key="7" tab="广告信息" >
                        <a-descriptions class="advert-wrap" layout="vertical">
                            <a-descriptions-item label="信息来源" :span="3">{{editData.data.ad_source_name}}</a-descriptions-item>
                            <a-descriptions-item label="项目名称" :span="3">{{editData.data.ad_project_name}}</a-descriptions-item>
                            <a-descriptions-item label="广告名称" :span="3">{{editData.data.ad_plan_name}}</a-descriptions-item>
                            <a-descriptions-item label="线索创建时间" :span="3">{{editData.data.create_time}}</a-descriptions-item>
                        </a-descriptions>
                    </a-tab-pane>
                </a-tabs>
            </div>
            
          
        </a-drawer>

        <!-- 分配线索 -->
        <a-modal v-model:open="allocationOpen" title="分配线索" @ok="handleOkAllocation">
            <a-form
                name="basic"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 19 }"
                autocomplete="off"
            >
                <a-form-item label="客服名称" name="username" >
                    <a-select
                        ref="select" v-model:value="allocationid" 
                        placeholder="客服名称"
                        allowClear
                    >
                        <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>

        <!-- 推送线索 -->
        <a-modal v-model:open="pushOpen" title="推送线索" @ok="handleOkpush">
            <a-form
                name="basic"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 19 }"
                autocomplete="off"
            >
                <a-form-item label="推送机构" name="pushid" >
                    <a-select
                        ref="select" v-model:value="pushid" 
                        placeholder="推送机构"
                        allowClear
                    >
                        <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>

        <!-- 导出 -->
        <a-modal v-model:open="exportOpen" title="导出线索" :footer="null">
            <div class="exportBtn">
                <a-button @click="toSelect(selectNum)" type="primary">导出选择（{{selectNum}}条）</a-button>
                <a-button @click="toAll(allNum)" type="primary">导出全部（{{allNum}}条）</a-button>
            </div>
        </a-modal>

        <!-- 回呼验证 -->
        <a-modal v-model:open="telOpen" title="呼叫登录验证" @ok="toCode">
             <a-form
                name="basic"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 18 }"
                autocomplete="off"
            >
                <a-form-item label="手机号码" name="telPhone" >
                    <a-input placeholder="手机号码" v-model:value="telPhone" />
                </a-form-item>
                <a-form-item label="验证码" name="phone" class="telVerfitywrap">
                    <a-input-group compact>
                        <a-input v-model:value="code" placeholder="请输入验证码" />
                        <a-button @click="getVerfity" :disabled="isdisable" style="height:30px">{{buttonText}}</a-button>
                    </a-input-group>
                </a-form-item>
             </a-form>
        </a-modal>

        <iframe ref="myIframe" :src="iframeUrl" width="100" height="100" style="position: absolute;z-index:-10"></iframe>

        <video ref="remoteVideo" autoplay></video>
        <video ref="localVideo" autoplay="autoplay" muted="muted"></video>
    </div>
</template>

<script>
const columns = [
  { title: 'ID', dataIndex: 'id' ,width:90},
  { title: '所属项目', dataIndex: 'title_clue_project',width:130 },
  { title: '姓名', dataIndex: 'username' ,width:90},
  { title: '性别', dataIndex: 'sex' ,width:70},
  { title: '年龄', dataIndex: 'age' ,width:70},
  { title: '手机号码', dataIndex: 'phone' ,width:130},
  { title: '地址', dataIndex: 'address' ,width:130},
  { title: '顾客详情', dataIndex: 'customer_details',width:360 },
  { title: '推送状态', dataIndex: 'is_push' ,width:90},
  { title: '推送机构', dataIndex: 'title_organ' ,width:120},
  { title: '线索状态', dataIndex: 'clue_state' ,width:90,key:"state"},
  { title: '线索标签', dataIndex: 'clue_tags_arr' ,width:120},
  { title: '项目名称', dataIndex: 'ad_project_name' ,width:110},
  { title: '广告名称', dataIndex: 'ad_plan_name' ,width:110},
  { title: '信息来源', dataIndex: 'ad_source_name' ,width:110},
  { title: '推送时间', dataIndex: 'push_time' ,width:120},
  { title: '添加时间', dataIndex: 'create_time',width:120 },
  { title: '归属人', dataIndex: 'username_belong_admin_user',width:110 },
  { title: '管理操作', dataIndex: 'action',width:110,fixed: 'right' }

];

const columns2 = [
  { title: 'ID', dataIndex: 'id' ,width:90},
  { title: '凭证', dataIndex: 'pic_src'},
  { title: '添加时间', dataIndex: 'create_time',width:180 },
  { title: '管理操作', dataIndex: 'action' }
];
import { reactive ,ref,computed} from '@vue/reactivity'
import { getCurrentInstance, nextTick,watch } from 'vue'
import { message,Modal} from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import {useRouter} from "vue-router";
import moment from "moment";
import "dayjs/locale/zh-cn";
import { formatDate } from "../../common/filter.js";
export default {
    data(){
        return{
            noClick: true
        }
    },
    setup(){
         const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const router=useRouter()
        // console.log("222")
        // console.log(router.currentRoute.value.query.state)
        let searchLlist=reactive({date:"",seat:undefined,jg:undefined,verify_state:undefined,username:"",phone:"",clue_state:1,is_push:undefined,clue_project_id:undefined})
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let headers=reactive({'token':sessionStorage.getItem("token")})
        let fpactiveKey=ref("11")
        let is_fp=ref(0)
        let changtab=function(){
            if(fpactiveKey.value=="11"){
                is_fp.value=0
            }else if(fpactiveKey.value=="12"){
                is_fp.value=1
            }
            getTabledata()
        }
        const rangePresets = ref([
            { label: '今天', value: [dayjs().add(0, 'd'), dayjs()] },
            { label: '昨天', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
            { label: '最近7天', value: [dayjs().add(-6, 'd'), dayjs()] },
            { label: '最近30天', value: [dayjs().add(-29, 'd'), dayjs()] },
        ]);

         // 机构列表
        let institutionList=reactive({arr:[]})
        let getInstitution=function(){
             proxy.$http.post('/admin/organlist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    institutionList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getInstitution()

        // 快捷切换
        let switchPage=function(e){

            proxy.$http.post('/admin/clueturnpage',{id:editid.value,type:e,clue_state:1,is_fp:"",is_push:searchLlist.is_push},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    if(res.data.data.id==""){
                        message.error("已经是最后一条");
                    }else{
                        editid.value=res.data.data.id
                        openRecord.value=true
                        editData.data=res.data.data
                        getTags(editData.data.clue_state)
                        if(editData.data.clue_tags){
                            var tag=editData.data.clue_tags.split(",")
                            editData.data.clue_tags=[]
                            for(var i in tag){
                                editData.data.clue_tags.push(parseInt(tag[i]))
                            }
                            
                        }
                        
                        getAll(editData.data.id)  
                    }
                    
                }else{
                    message.error(res.data.msg);
                }
            });
        }

          // 列表
          let projectList=reactive({arr:[]})
         let getProject=function(){
            proxy.$http.post('/admin/clueprojectlist',{pagesize:200},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    projectList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getProject()


        // 通话记录
        let tellList=reactive({arr:[]})
        let getTell=function(){
            proxy.$http.post('/admin/phonecallloglist',{pagesize:500},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    tellList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // getTell()
         // 操作记录
        let toolList=reactive({arr:[]})
        let getTool=function(){
            proxy.$http.post('/admin/clueloglist',{pagesize:500},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    toolList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // getTool()
        let allList=reactive({arr:[]})
        let getAll=function(id){
             proxy.$http.post('/admin/cluelogandphonecallloglist',{pagesize:500,clue_id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    allList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 单击
        let doubleClick=function(record){
            // console.log("111")
            return {
                onClick: (event) => {
                    console.log(event);
                // 双击行事件处理
                    if (event.defaultPrevented) {
                        // 行内操作按钮被点击
                        console.log('行内操作按钮被点击', record);
                    } else {
                    //     // 行被点击
                        openAll(record.id)
                        getAll(record.id)
                        console.log('行被点击', record);
                    }
                   
                    // console.log('Double clicked on row:', record.id);
                },
            }
        }



         // 正计时
        let teltime=ref("00:00:00")
        let flag=ref(null)
        let hour=ref(0)
        let minute=ref(0)
        let second=ref(0)
         //开始计时
        let start=function() {
            flag.value= setInterval(() => {
                second.value = second.value + 1;
                if (second.value >= 60) {
                second.value = 0;
                minute.value = minute.value + 1;
                }

                if (minute.value >= 60) {
                minute.value = 0;
                hour.value = hour.value + 1;
                }
                teltime.value =
                complZero(hour.value) +
                ":" +
                complZero(minute.value) +
                ":" +
                complZero(second.value);
            }, 1000);
        }

         //暂停计时
        let end=function() {
            window.clearInterval(flag.value);
            hour.value = 0;
            minute.value = 0;
            second.value = 0;
            teltime.value = "00:00:00";
        }

            //补零
        let complZero=function(n) {
        return n < 10 ? "0" + n : "" + n;
        }

       

        // 客服列表
        let userList=reactive({arr:[]})
        let getUserlist=function(){
            proxy.$http.post('/admin/adminuserlist',{pagesize:500,admin_type_id:3},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    userList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getUserlist()

        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
            selectNum.value=selectedRowKeys.value.length
        }
        // 分配线索
        let allocationid=ref()
        let clueid=ref()
        let allocationOpen=ref(false)
        let toAllocationone=function(id){
            clueid.value==id
        }
        let toAllocation=function(){
            if(selectedRowKeys.value==undefined){
                message.error("请选择要分配的线索")
            }else{
                allocationOpen.value=true
                clueid.value=selectedRowKeys.value.join(",")
            }
           
        }
        let handleOkAllocation=function(){
            proxy.$http.post('/admin/allocationclue',{admin_user_id:allocationid.value,clue_ids:clueid.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    allocationOpen.value=false
                    if(selectedRowKeys.value){
                        selectedRowKeys.value.splice(0,selectedRowKeys.value.length)
                    }
                    message.success(res.data.msg);
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }

         // 推送线索
        let pushid=ref()
        let pushOpen=ref(false)
        let allid=ref()
        let toPush=function(){
            if(selectedRowKeys.value==undefined){
                message.error("请选择要推送的线索")
            }else{
                pushOpen.value=true
                allid.value=selectedRowKeys.value.join(",")
            }
           
        }
        let toPushone=function(id){
            pushOpen.value=true
            allid.value=id
        }
        let handleOkpush=function(){
            proxy.$http.post('/admin/pushclue',{organ_id:pushid.value,ids:allid.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    pushOpen.value=false
                    if(selectedRowKeys.value){
                        selectedRowKeys.value.splice(0,selectedRowKeys.value.length)
                    }
                    var msg1=""
                    var msg2=""
                    var msg3=""
                    if(parseInt(res.data.data.count_fail_xscf.no)>0){
                       msg1='，重复 '+res.data.data.count_fail_xscf.no+' 条'
                    }else{
                         msg1=""
                    }
                    if(parseInt(res.data.data.count_fail_xsbcz.no)>0){
                       msg1='，线索不存在 '+res.data.data.count_fail_xsbcz.no+' 条'
                    }else{
                         msg2=""
                    }
                    if(parseInt(res.data.data.count_fail_jfbz.no)>0){
                       msg1='，积分不足 '+res.data.data.count_fail_jfbz.no+' 条'
                    }else{
                         msg3=""
                    }
                    
                    // var timer=setTimeout(function() {
                    //     console.log(msg1)
                        message.success('推送成功 '+res.data.data.count_succsss.no+' 条'+msg1+msg2+msg3);
                    //     clearTimeout(timer)
                    // },2000)
                       

                    
                    
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 搜索
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        // 线索状态
        let cluestatuslist=reactive({arr:[]})
        let getcluestatuslist=function(){
            proxy.$http.post('/admin/cluestatelist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    cluestatuslist.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getcluestatuslist()

        // 添加
        let activeKey=ref("1")
        let formData=reactive({clue_project_id:undefined,username:"",phone:"",file_url:"",
        sex:undefined,organ_id:"",customer_details:"",age:"",address:"",ad_project_name:"",ad_plan_name:"",ad_source_name:""})
        let clueOpen=ref(false)
        let uploadData=reactive({jq:"",fileurl:""})
        let toAdd=function(){
            formData.username=""
            formData.phone=""
            formData.clue_project_id=undefined
            formData.sex=undefined
            formData.customer_details=""
            formData.age=""
            formData.address=""
            formData.ad_project_name=""
            formData.ad_plan_name=""
            formData.ad_source_name=""
            clueOpen.value=true
        }
        let handleOkAdd=function(){
            if(activeKey.value=="1"){
                proxy.$http.post('/admin/addclue',{username:formData.username,phone:formData.phone,
                sex:formData.sex,age:formData.age,clue_project_id:formData.clue_project_id,
                customer_details:formData.customer_details,address:formData.address,ad_project_name:formData.ad_project_name,ad_plan_name:formData.ad_plan_name
                ,ad_source_name:formData.ad_source_name,clue_state:1,file_url:formData.file_url,is_fp:1},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        clueOpen.value=false
                        message.success(res.data.msg)
                        if(fileAudio.value){
                            fileAudio.value.splice(0,fileAudio.value.length)
                        }
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(activeKey.value=="2"){
                    proxy.$http.post('/admin/importclue',{clue_project_id:uploadData.jq,file_url:uploadData.fileurl,clue_state:1},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            clueOpen.value=false
                            // fileList2.value=""
                            if(fileList2.value){
                                fileList2.value.splice(0,fileList2.value.length)
                            }
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            if(fileList2.value){
                                fileList2.value.splice(0,fileList2.value.length)
                            }
                            message.error(res.data.msg);
                        }
                    });
            }
        }

        // 导入文件
        let fileList2=ref()
        // const beforeUpload = (file,fileList) => {
        //     fileList2.value = file;
        //     return false; // 返回 false 取消默认上传行为
        // };
         let handleChangeExport=function(info){
            if (info.file.status === 'uploading') {
                // loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                // loading.value = false;
                console.log(info.fileList)
                uploadData.fileurl=info.fileList[0].response.data.file_src
            }else if(info.file.status === 'error'){
                message.error("上传失败");
            }
        }

        let selectNum=ref(0)
        let allNum=ref(0)

        let exportOpen=ref(false)
        let openExoprt=function(){
            exportOpen.value=true
        }

        // 线索标签
        let taglist=reactive({arr:[]})
        let getTags=function(e){
            var id=e
            if(e.target){
                id=e.target.value
            }
            proxy.$http.post('/admin/cluetaglist',{pagesize:200,clue_state_id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    taglist.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 导出选择
        let toSelect=function(num){
            var ids=selectedRowKeys.value.join(",")
            toExport(ids,num)
        }
        
        // 导出全部
         let toAll=function(num){
            var ids=""
            toExport(ids,num)
        }

        let toExport=function(ids,num){

             var date=""
            if(searchLlist.date){
                date=moment(searchLlist.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist.date[1]+" 23:59:59").unix()
            }
            // console.log(date)
            var state=0
            if(router.currentRoute.value.query.state){
                state=router.currentRoute.value.query.state
            }
            proxy.$http.post('/admin/exportclueurl',{ids:ids,rows:num,phone:searchLlist.phone,
            clue_state:searchLlist.clue_state,is_fp:1,is_push:searchLlist.is_push},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    selectNum.value=0
                    if(selectedRowKeys.value){
                        selectedRowKeys.value.splice(0,selectedRowKeys.value.length)
                    }
                    message.success("文件下载");
                    // console.log(res.data.result.href)
                    let downloadElement = document.createElement('a')
                    // 创建下载的链接
                    // let href = window.URL.createObjectURL(res.data.result.href)
                    downloadElement.style.display = 'none'
                    downloadElement.href = res.data.data.href
                    // 下载后文件名
                    // downloadElement.download = fileName
                    document.body.appendChild(downloadElement)
                    // 点击下载
                    downloadElement.click()
                    // 下载完成移除元素
                    document.body.removeChild(downloadElement)
                    // 释放掉blob对象
                    window.URL.revokeObjectURL(res.data.result.href)
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 列表
         if(sessionStorage.getItem("admintype")==1||sessionStorage.getItem("admintype")==2){
            is_fp.value=0
        }else{
            is_fp.value=1
        }
        
        var istab=ref(true)
         watch(() => router.currentRoute.value.query.state, (newParams, oldParams) => {
            // console.log(router.currentRoute.value.query.state)
            // searchLlist.phone=""
            // if(sessionStorage.getItem("admintype")==1||sessionStorage.getItem("admintype")==2){
            //     is_fp.value=0
            // }else{
            //     is_fp.value=1
            // }
            // if(router.currentRoute.value.query.state&&router.currentRoute.value.query.state!=undefined){
                 
            //     istab.value=false
            //     page.page=1
            //     searchLlist.clue_state=parseInt(router.currentRoute.value.query.state)
            // }else{
            //     istab.value=true
            //     page.page=1
            //     searchLlist.clue_state=0
            // }
           
            // getTabledata()
         })
        let page=reactive({page:"",pageSize:10,total:20})//分页
        let getTabledata=function(){
            // var date=""
            // if(searchLlist.date){
            //     date=moment(searchLlist.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist.date[1]+" 23:59:59").unix()
            // }
            // var state=""
            proxy.$http.post('/admin/cluelist',{page:page.page,pagesize:page.pageSize,phone:searchLlist.phone,
            clue_state:1,is_fp:"",is_push:searchLlist.is_push,clue_project_id:searchLlist.clue_project_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                    allNum.value=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()



        // 上传凭证

        let loading=ref(false)
    
        // 通话记录
        let openRecord=ref(false)
        // openRecord.value=false
       
        // let deawerData1=reactive({data:{}})
        let isEdit=ref(false)
        let isDrawerEdit=function(){
            isEdit.value=true
        }
        let quitDrawerEdit=function(){
            openAll(editData.data.id)
            isEdit.value=false
        }

        // let deawerData2=reactive({data:{}})
        let isEdit2=ref(false)
        let isDrawerEdit2=function(){
            isEdit2.value=true
        }
        let quitDrawerEdit2=function(){
            openAll(editData.data.id)
            isEdit2.value=false
        }
         let edithandleOk2=function(){
            if(editData.data.clue_tags&&editData.data.clue_tags.length>0){
                var clue_tags=editData.data.clue_tags.join(",")
            }
            
            proxy.$http.post('/admin/updateclue',{id:editData.data.id,clue_tags:clue_tags,clue_state:editData.data.clue_state},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    editOpen.value=false
                    // openRecord.value=false
                    isEdit2.value=false
                    isEdit.value=false
                    editOpen2.value=false
                    editOpen3.value=false
                    message.success(res.data.msg)
                    if(fileAudio.value){
                        fileAudio.value.splice(0,fileAudio.value.length)
                    }
                    
                    getTabledata()
                    openAll(editid.value)
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let editid=ref()
        let openAll=function(id){
            editid.value=id
             proxy.$http.post('/admin/cluepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    openRecord.value=true
                    editData.data=res.data.data.data
                    getTags(editData.data.clue_state)
                    if(editData.data.clue_tags){
                        var tag=editData.data.clue_tags.split(",")
                        editData.data.clue_tags=[]
                        for(var i in tag){
                            editData.data.clue_tags.push(parseInt(tag[i]))
                        }
                        
                    }
                    
                    getAll(editData.data.id)
                }else{
                    message.error(res.data.msg);
                }
            });
            // deawerData1.data={name:"Zhou Maomao",tel:"13888888888",sex:"2",age:"30",details:"No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China"}
            // deawerData2.data={status:"2",tips:"No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China"}
            
        }

        // 编辑
        let editOpen2=ref(false)
        let openState=function(){
            
            editOpen2.value=true
        }
        let editOpen3=ref(false)
        let openVerify=function(){
            
            editOpen3.value=true
        }
        let editData=reactive({data:{username:"",phone:"",address:"",sex:"",age:"",customer_details:"",clue_state:"",clue_tags:"",ad_project_name:"",ad_source_name:"",ad_source_name:""}})
        let editOpen=ref(false)
        let openEdit=function(id){
            proxy.$http.post('/admin/cluepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token"),
                  
                }
            }).then((res) => {
                if(res.data.status==200){
                    if(fileAudio.value){
                        fileAudio.value.splice(0,fileAudio.value.length)
                    }
                    editOpen.value=true
                    editData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let edithandleOk=function(){
            if(editData.data.clue_tags&&editData.data.clue_tags.length>0){
                var clue_tags=editData.data.clue_tags.join(",")
            }
            proxy.$http.post('/admin/updateclue',{id:editData.data.id,username:editData.data.username,phone:editData.data.phone,sex:editData.data.sex,
            customer_details:editData.data.customer_details,age:editData.data.age,address:editData.data.address,clue_tags:clue_tags,clue_state:1,file_url:editData.data.file_url,
             wx_chat:editData.data.wx_chat,QQ:editData.data.QQ
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    editOpen.value=false
                    // openRecord.value=false
                    isEdit2.value=false
                    isEdit.value=false
                    editOpen2.value=false
                    editOpen3.value=false
                    message.success(res.data.msg)
                    if(fileAudio.value){
                        fileAudio.value.splice(0,fileAudio.value.length)
                    }
                    
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let isEditorgan=ref(false)

        let editCustomer=function(id,remarks){
            editData.data.id=id
            editData.data.customer_details=remarks
            edithandleOk()
        }
        let editAge=function(id,age){
            editData.data.id=id
            editData.data.age=age
            edithandleOk()
        }
        let editSex=function(id,sex){
            editData.data.id=id
            editData.data.sex=sex
            edithandleOk()
        }
     


        // 删除凭证
        let delVoucher=function(id){
            proxy.$http.post('/admin/resetstateremark',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg)
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // 上传录音
        let fileAudio=ref()
        let handleChangeAdd=function(info){
            if (info.file.status === 'uploading') {
                return;
            }else if(info.file.status === 'done'){
                formData.file_url=info.fileList[0].response.data.file_src
            }else if(info.file.status === 'error'){
                message.error("上传失败");
            }
        }
        let handleChangeEdit=function(info){
            if (info.file.status === 'uploading') {
                return;
            }else if(info.file.status === 'done'){
                editData.data.file_url=info.fileList[0].response.data.file_src
                if(openRecord.value=true){
                    isEdit.value=true
                }
            }else if(info.file.status === 'error'){
                message.error("上传失败");
            }
        }

         // 回呼
         let iframeUrl="https://fxpd.13524.net/tel.html"
         const myIframe = ref(null);

        // 获取个人信息
        let admin_seats_username=ref()
        let admin_seats_password=ref()
        let phone_type=ref()

        let extCode1=ref()
        let extPass1=ref()
        let siplocal1=ref()
        let wssport1=ref()
        let getuserinfo=function(){
             proxy.$http.post('/admin/adminuserpage',{id:sessionStorage.getItem("id")},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    admin_seats_username.value=res.data.data.data.admin_seats_username
                    admin_seats_password.value=res.data.data.data.admin_seats_password
                    phone_type.value=res.data.data.data.phone_call_api_type

                    extCode1.value=res.data.data.data.sip_extension
                    extPass1.value=res.data.data.data.sip_password
                    siplocal1.value=res.data.data.data.sip_localhost
                    wssport1.value=res.data.data.data.sip_port
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getuserinfo()

        let apiurl=ref()
        var wp=""
        let toTel = function(id,phone){
            
            
            proxy.$http.post('/admin/phonecall',{clue_id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    // message.success(res.data.msg);

                    if(openRecord.value==false){
                        openAll(id)
                    }
                    console.log(myIframe.value)
                    isEdit.value=true
                    if(phone_type.value==2){
                        if (myIframe.value && myIframe.value.contentWindow) {
                            const iframeMethod = myIframe.value.contentWindow.ant_sys_login;
                        
                            if (typeof iframeMethod === 'function') {
                                let logininfo=iframeMethod("wxkj",admin_seats_username.value,admin_seats_password.value,"vs01.wei-fu.cn"); // 调用内嵌页面中的方法
                                console.log(logininfo)
                                let token=logininfo.data.token

                                const iframeWindow = myIframe.value.contentWindow;
                                console.log( myIframe.value.contentWindow)
                                const MyObject = iframeWindow.MyObject;
                            
                                wp=new MyObject(token,"vs01.wei-fu.cn","7443",function(data){
                                    console.log(data);
                                    if(data.value=="accepted"||data.value=="trackAdded"){
                                        ishangup.value=true
                                    }else{
                                        ishangup.value=false
                                    }
                                });
                                console.log(wp);
                                console.log(phone);
                                wp.ant_register()
                                wp.ant_endcall();
                                wp.ant_startcall(phone)
                            }
                        }
                    }else if(phone_type.value==3){
                        
                        toPbx(phone)
                    }
                    
                    
                }else if(res.data.data.api){
                    telOpen.value=true
                    apiurl.value=res.data.data.api
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // pbx拨打
        var sessionall;
        var remoteVideo = ref(null);
		var localVideo = ref(null);
        let toPbx=function(phone){
            var extCode = extCode1.value; //SIP分机号
            var extPass = extPass1.value; //SIP分机密码
            var siplocal = siplocal1.value;//SIP服务器地址 
            var wssport = wssport1.value;//wss端口号
            
            var config = {
            uri: "sip:" + extCode + '@'+siplocal,
            authorizationUser: extCode,
            password: extPass,
            displayName: extCode,
            log: {
                builtinEnabled: true,
                level: 3 // log日志级别
            },
            transportOptions: {
                wsServers: ['wss://'+siplocal+':'+wssport], //wss协议
                traceSip: true  //开启sip日志,用于排查问题
            },
            allowLegacyNotifications: true,
            hackWssInTransport: true, // 设置为true 则注册时 transport=wss; false:transport=ws;

            //hackIpInContact: "39.100.87.201",
            hackIpInContact: true,
            userAgentString: "smarkdeskclient",
            registerOptions: {
                expires: 300,
                registrar: 'sip:registrar.mydomain.com',
            },

            contactName: "1003",
            };

            var ua = new SIP.UA(config);
            // 接受入站（用户代理服务器）会话
            ua.on('invite', function (session) {
                    

            var url = session.remoteIdentity.uri.toString() + "来电了，是否接听";

          

            session.on("terminated", function (message, cause) {
                console.error(message);

            })

            /**
             *
             */
            session.on('accepted', function (response, cause) {

                // If there is a video track, it will attach the video and audio to the same element
                var pc = this.sessionDescriptionHandler.peerConnection;
                console.error(this.sessionDescriptionHandler);
                var remoteStream;

                if (pc.getReceivers) {
                remoteStream = new window.MediaStream();
                pc.getReceivers().forEach(function (receiver) {
                    var track = receiver.track;
                    if (track) {
                    remoteStream.addTrack(track);
                    }
                });
                } else {
                remoteStream = pc.getRemoteStreams()[0];
                }
                remoteVideo.value.srcObject = remoteStream;


                var localStream_1;
                if (pc.getSenders) {
                localStream_1 = new window.MediaStream();
                pc.getSenders().forEach(function (sender) {
                    var track = sender.track;
                    if (track && track.kind === "video") {
                    localStream_1.addTrack(track);
                    }
                });
                }
                else {
                localStream_1 = pc.getLocalStreams()[0];
                }
                localVideo.value.srcObject = localStream_1;
                localVideo.value.volume = 0;
                

            })

            session.on('bye', function (resp, cause) {
            });


            session.on("terminated", function (message, cause) {
                console.log('terminate'+cause);
            });

            var isaccept = confirm(url);
            if (isaccept) {
                //接受来电
                session.accept({
                sessionDescriptionHandlerOptions: {
                    constraints: {
                    audio: true,
                    video: false
                    }
                }
                });
                sessionall = session;
            }
            else {
                //拒绝来电
                session.reject();
            }

            });

            //注册失败
            ua.on('registrationFailed', function (response, cause) {
            alert('注册失败'+cause)
            });

            toHangup()
            //创建一个新的出站（用户代理客户端）会话
			sessionall = ua.invite(phone, {
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: true,
						video: false
					},
					alwaysAcquireMediaFirst: true // 此参数是sip.js官方修复在firefox遇到的bug所设置
				}
			});

      

            //处理接受183早期媒体
			sessionall.on('trackAdded', function () {
                ishangup.value=true
				var pc = this.sessionDescriptionHandler.peerConnection;
				var remoteStream;

				if (pc.getReceivers) {
					remoteStream = new window.MediaStream();
					pc.getReceivers().forEach(function (receiver) {
						var track = receiver.track;
						if (track) {
							remoteStream.addTrack(track);
						}
					});
				} else {
					remoteStream = pc.getRemoteStreams()[0];
				}
				remoteVideo.value.srcObject = remoteStream;
				var localStream_1;
				if (pc.getSenders) {
					localStream_1 = new window.MediaStream();
					pc.getSenders().forEach(function (sender) {
						var track = sender.track;
						if (track && track.kind === "video") {
							localStream_1.addTrack(track);
						}
					});
				}
				else {
					localStream_1 = pc.getLocalStreams()[0];
				}
				localVideo.value.srcObject = localStream_1;
			});

			//每次收到成功的最终（200-299）响应时都会触发。
			sessionall.on("accepted", function (response, cause) {
                ishangup.value=true
                start()
				var pc = this.sessionDescriptionHandler.peerConnection;
				var remoteStream;

				if (pc.getReceivers) {
					remoteStream = new window.MediaStream();
					pc.getReceivers().forEach(function (receiver) {
						var track = receiver.track;
						if (track) {
							remoteStream.addTrack(track);
						}
					});
				} else {
					remoteStream = pc.getRemoteStreams()[0];
				}
				remoteVideo.value.srcObject = remoteStream;


				var localStream_1;
				if (pc.getSenders) {
					localStream_1 = new window.MediaStream();
					pc.getSenders().forEach(function (sender) {
						var track = sender.track;
						if (track && track.kind === "video") {
							localStream_1.addTrack(track);
						}
					});
				}
				else {
					localStream_1 = pc.getLocalStreams()[0];
				}
				localVideo.value.srcObject = localStream_1;
			})

			//挂机时会触发
			sessionall.on("bye", function (response, cause) {
				console.log(response);
                sessionall="";
                end()
                ishangup.value=false
			})

			//请求失败时触发，无论是由于最终响应失败，还是由于超时，传输或其他错误。
			sessionall.on("failed", function (response, cause) {
				console.log(response);
                ishangup.value=false
                end()
			})

			/**
			 *
			 */
			sessionall.on("terminated", function (message, cause) {
                ishangup.value=false
                end()
			})

			/**
			 * 对方拒绝
			 */
			sessionall.on('rejected', function (response, cause) {
                ishangup.value=false
                end()
			})

			sessionall.on('progress', function (response) {
                ishangup.value=false
                end()
				// InviteClientContext#receiveInviteResponse
				if (response.statusCode === 183 && response.body && this.hasOffer && !this.dialog) {
					if (!response.hasHeader('require') || response.getHeader('require').indexOf('100rel') === -1) {
						if (this.sessionDescriptionHandler.hasDescription(response.getHeader('Content-Type'))) {
							// Mimic rel-100 behavior

							if (!this.createDialog(response, 'UAC')) { // confirm the dialog, eventhough it's a provisional answer
								return
							}
							this.hasAnswer = true
							this.dialog.pracked.push(response.getHeader('rseq'))
							this.status = SIP.Session.C.STATUS_EARLY_MEDIA
							this.sessionDescriptionHandler
								.setDescription(response.body, this.sessionDescriptionHandlerOptions, this.modifiers)
								.catch((reason) => {
									this.logger.warn(reason)
									this.failed(response, C.causes.BAD_MEDIA_DESCRIPTION)
									this.terminate({ status_code: 488, reason_phrase: 'Bad Media Description' })
								})




						}
					}
				}
			});
        }

        // 挂断
        let ishangup=ref(false)
        let toHangup=function(){
            if(phone_type.value==2){
                wp.ant_endcall();
                ishangup.value=false
            }else if(phone_type.value==3){
                if (!sessionall) {
                    return;
                } else if (sessionall.startTime) { // Connected
                    sessionall.bye();
                } else if (sessionall.reject) { // Incoming
                    sessionall.reject();
                } else if (sessionall.cancel) { // Outbound
                    sessionall.cancel();
                }
            }
            
        }

        // 回呼验证
        let telOpen=ref(false)
        let telPhone=ref(sessionStorage.getItem("telphone"))
        let code=ref()
        let countdown=ref()
        let isdisable=ref(false)
        let buttonText=ref('获取验证码')
        // 获取验证码
        let getVerfity=function(){
            proxy.$http.post('/admin/sendverifycode',{telephone:telPhone.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    sessionStorage.setItem("telphone",telPhone.value)
                    message.success(res.data.msg);
                    countdown.value = 60
                    isdisable.value=true
                    const timer = setInterval(() => {
                        countdown.value--
                        buttonText.value = countdown.value+'秒后获取'
                        if (countdown.value === 0) {
                            isdisable.value=false
                            clearInterval(timer)
                            buttonText.value = '获取验证码'
                        }

                    }, 1000)
                }else{
                    message.error(res.data.msg);
                }
            });
            
        }
        let toCode=function(){
            if(phone_type.value==2){
                proxy.$http.post('/admin/verify_admin_seats_ycwydh',{admin_user_id:sessionStorage.getItem("id"),code:code.value,telephone:telPhone.value},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg);
                        // if(openRecord.value==false){
                        //     // openRecord.value=true
                        //     openAll()
                        // }
                        telOpen.value=false
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else{
                proxy.$http.post('/admin/verify_admin_seats_ippbx',{code:code.value,telephone:telPhone.value},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg);
                        // if(openRecord.value==false){
                        //     // openRecord.value=true
                        //     openAll()
                        // }
                        telOpen.value=false
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }

        // 放弃线索
        let giveupMore=function(){
            //  console.log()
            console.log(selectedRowKeys.value)
            if(selectedRowKeys.value==undefined){
                message.error("请选择要放弃的线索")
               
            }else{
                var id=selectedRowKeys.value.join(",")
                giveup(id)
            }
           
        }
        let giveup=function(id){
            Modal.confirm({
                title: '你确定要放弃线索至公海吗？',

            onOk() {
                proxy.$http.post('/admin/deallocationclue',{clue_ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        if(selectedRowKeys.value){
                            selectedRowKeys.value.splice(0,selectedRowKeys.value.length)
                        }
                        selectNum.value=0
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
        }

       


          // 删除
        let delMore=function(){
            //  console.log()
            console.log(selectedRowKeys.value)
            if(selectedRowKeys.value==undefined){
                message.error("请选择要删除的线索")
               
            }else{
                var id=selectedRowKeys.value.join(",")
                del(id)
            }
           
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',

            onOk() {
                proxy.$http.post('/admin/delclue',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        if(selectedRowKeys.value){
                            selectedRowKeys.value.splice(0,selectedRowKeys.value.length)
                        }
                        selectNum.value=0
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
        }

         // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return{
            reload,
            teltime,
            toPush,handleOkpush,pushid,pushOpen,changeFormdate,toPushone,
            switchPage,
            remoteVideo,localVideo,
            giveupMore,giveup,istab,
            changtab,
            getTags,taglist,
            cluestatuslist,projectList,
            fpactiveKey,
            ishangup,toHangup,
            telOpen,telPhone,code,isdisable,getVerfity,countdown,buttonText,toCode,iframeUrl,myIframe,edithandleOk2,
            fileAudio,handleChangeAdd,handleChangeEdit,
            isEditorgan,editCustomer,editSex,editAge,
            tellList,toolList,doubleClick,
            handleChangeExport,fileList2,uploadData,selectNum,allNum,toSelect,toAll,exportOpen,openExoprt,allList,
            toTel,
            // limits,
            userList,allocationid,allocationOpen,toAllocation,handleOkAllocation,toAllocationone,
            institutionList,
            openRecord,isEdit,isDrawerEdit,quitDrawerEdit, isEdit2,isDrawerEdit2,quitDrawerEdit2,openAll,getAll,
            editData,editOpen,openEdit,edithandleOk,editOpen3,editOpen2,openVerify,openState,
            del,delVoucher,delMore,selectedRowKeys,onSelectChange,
            clueOpen,toAdd,formData,activeKey,handleOkAdd,loading,headers,toSearch,
            pageChange,getTabledata,dataList,page,rangePresets,
            searchLlist,columns
        }
    }
}
</script>